import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import loadable from '@loadable/component';
import { useLocation } from '@reach/router';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import { useEventQuery } from '../GraphQl/useEventQuery';
import { PageMeta } from '@components/pageMeta';
import { PortalGrid } from '../components/portals/portalGrid';
import { useSiteContext, useFilters } from '@lib/context';
import { EventFilters } from '../components/nav/filters/eventFilters';
import { searchClient } from '@lib/algolia';

const FeaturedLinks = loadable(() =>
  import('../components/pageModules/modules/featuredLinks'),
);

const EventPortalTemplate = ({ data: { page }, location }) => {
  const { updateDate, updateSeries } = useFilters();
  const { search } = useLocation();
  const prefilter = {
    from: 'Sun Jan 01 2023 00:00:00 GMT+1300 (New Zealand Daylight Time)',
    to: 'Sun Dec 31 2023 00:00:00 GMT+1300 (New Zealand Daylight Time)',
  };

  useEffect(() => {
    if (search.split('=')[0] === '?2023-season') {
      updateDate(prefilter?.from, prefilter?.to);
      updateSeries('special');
      updateSeries('cmnz');
    }

    if (search.split('=')[0] === '?cmnz') {
      updateSeries('cmnz');
    }

    if (search.split('=')[0] === '?partnership') {
      updateSeries('partnership');
    }
    try {
      var klaviyo = klaviyo || [];
      var page = window.location.href;
      klaviyo.push([
        'track',
        'Viewed Page',
        { url: 'https://www.chambermusic.co.nz/events' },
      ]);
    } catch (err) {
      console.log('Error occurred adding klaviyo script: ' + err);
    }
  }, []);

  const { seo, config } = page || {};
  const { nodes } = useEventQuery();
  const { state } = useSiteContext();

  //filter out nodes that don't include any state.filters.artists in their featuredArtists array
  const filteredNodes = nodes.filter((node) => {
    if (state.filters.artists.length > 0) {
      return node.featuredArtists.some((artist) =>
        state.filters.artists.includes(artist?.name),
      );
    }
    return true;
  });
  //filter out nodes that don't include any state.filters.locations in their sessions array
  const filteredNodes2 = filteredNodes.filter((node) => {
    if (state.filters.locations.length > 0) {
      return node.sessions.some((session) =>
        state.filters.locations.includes(
          session?.venue?.location || session?.smallVenueLocation,
        ),
      );
    }
    return true;
  });
  //filter out nodes that aren't between state.filters.dates.from and state.filters.dates.to
  const filteredNodes3 = filteredNodes2.filter((node) => {
    if (state.filters.dates.from && state.filters.dates.to) {
      return node.sessions.some(
        (session) =>
          new Date(session.time) >= new Date(state.filters.dates.from) &&
          new Date(session.time) <= new Date(state.filters.dates.to),
      );
    }
    return true;
  });
  //filter out nodes that where the state.filters.series does not contain node.series
  const filteredNodes4 = filteredNodes3.filter((node) => {
    if (state.filters.series.length > 0) {
      return (
        state.filters.series.includes(node?.series) ||
        (state.filters.series.includes('special') && node.specialConcert)
      );
    }
    return true;
  });

  return (
    <Layout config={config}>
      {/* SEO */}
      <PageMeta {...seo} />
      {/* Filters */}
      <InstantSearch searchClient={searchClient} indexName='events'>
        <Configure hitsPerPage={600} />
        <EventFilters />
        <PortalGrid enable nodes={filteredNodes4} {...location?.state} />
      </InstantSearch>
      {/* Module at bottom of page */}
      <FeaturedLinks reversed className='pt-space-m pb-space-m' />
    </Layout>
  );
};

export default EventPortalTemplate;

export const simpleEventPortalQuery = graphql`
  query standardEventPortalQuery($slug: String!) {
    page: sanityEventPortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
    }
  }
`;
