import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useVenueQuery = () => {
  const { allSanityVenue } = useStaticQuery(graphql`
    query {
      allSanityVenue {
        nodes {
          title
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
          }
          location
          excerpt
        }
      }
    }
  `);
  return allSanityVenue || {};
};
