import React from 'react';
import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';

import { useSiteContext } from '@lib/context';
import { useVenueQuery } from '@querys/useVenueQuery';
import { useArtistQuery } from '@querys/useArtistQuery';
import { FilterSwitch } from '../../pageModules/concertBanner/filterSwitch';
import { FilterSearchBox } from '@components/search/filterSearchBox';
import { MobileFilters } from './mobileFilters';

import Chevron from '@svg/chevron-down.svg';
import { DesktopSearch } from './desktopSearch';

export const EventFilters = () => {
  const { state } = useSiteContext();

  //Filtering Steps
  const [step, setStep] = React.useState(false);

  const getLocation = (id) => {
    const element = document?.getElementById(id);
    const rect = element?.getBoundingClientRect();
    return {
      x: rect?.x - 320,
      y: window?.pageYOffset + rect?.y,
    };
  };

  const { nodes } = useVenueQuery();
  const { artists } = useArtistQuery();

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [searchActive, setSearchActive] = React.useState(false);
  const toggleSearch = () => setSearchActive((prev) => !prev);

  const breakpoints = useBreakpoint();

  const { scrollY } = useViewportScroll();
  const height = useTransform(scrollY, [0, 163], [192, 32]);

  return (
    <>
      {breakpoints.lg && (
        <div style={{ paddingTop: '294px' }} className='w-full' />
      )}
      <motion.div
        style={{ paddingTop: breakpoints.lg && height }}
        className={cx(
          'px-gutter pb-8 flex gap-y-10 flex-col lg:flex-row items-center w-full justify-between nav-blur',
          {
            'z-50': (searchActive || filtersOpen) && !breakpoints.lg,
            'z-20': !searchActive && !filtersOpen,
            'fixed top-0 left-0 z-20': breakpoints.lg,
            'pt-40 relative': !breakpoints.lg,
          },
        )}>
        <span className='blockH2 text-teal'>What's on</span>
        {breakpoints.lg && (
          <div className='hidden lg:flex gap-x-3 items-center'>
            <AnimatePresence exitBeforeEnter>
              {searchActive ? (
                <motion.div
                  key={searchActive}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  <FilterSearchBox />
                </motion.div>
              ) : (
                <motion.div
                  className='flex gap-x-3 items-center'
                  key={searchActive}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  <button
                    id='series'
                    onClick={() => setStep('series')}
                    className='btn-standard bg-teal bg-opacity-10 text-teal flex items-center gap-x-4'>
                    <span>
                      {state?.filters?.series?.length > 0
                        ? `${state?.filters?.series?.length} Series`
                        : `All Series`}
                    </span>
                    <Chevron className='w-2' />
                  </button>
                  <button
                    id='dates'
                    onClick={() => setStep('dates')}
                    className='btn-standard bg-teal bg-opacity-10 text-teal flex items-center gap-x-4'>
                    <span>
                      {state?.filters?.dates?.to
                        ? `${new Date(
                            state?.filters?.dates?.from,
                          ).toLocaleDateString('en-US')} - ${new Date(
                            state?.filters?.dates?.to,
                          ).toLocaleDateString('en-US')}`
                        : `Anytime`}
                    </span>
                    <Chevron className='w-2' />
                  </button>
                  <button
                    id='locations'
                    onClick={() => setStep('locations')}
                    className='btn-standard bg-teal bg-opacity-10 text-teal flex items-center gap-x-4'>
                    <span>
                      {state?.filters?.locations?.length > 0
                        ? state?.filters?.locations?.length === 1
                          ? `${state?.filters?.locations?.length} Location`
                          : `${state?.filters?.locations?.length} Locations`
                        : `Anywhere`}
                    </span>
                    <Chevron className='w-2' />
                  </button>
                  <button
                    id='artists'
                    onClick={() => setStep('artists')}
                    className='btn-standard bg-teal bg-opacity-10 text-teal flex items-center gap-x-4'>
                    <span>
                      {state?.filters?.artists?.length > 0
                        ? state?.filters?.artists?.length === 1
                          ? `${state?.filters?.artists?.length} Artist`
                          : `${state?.filters?.artists?.length} Artists`
                        : `Anyone`}
                    </span>
                    <Chevron className='w-2' />
                  </button>
                </motion.div>
              )}
            </AnimatePresence>
            <DesktopSearch
              searchActive={searchActive}
              toggleSearch={toggleSearch}
            />
          </div>
        )}
      </motion.div>

      {!breakpoints.lg && (
        <MobileFilters
          setStep={setStep}
          searchOpen={searchActive}
          setSearchOpen={setSearchActive}
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
        />
      )}

      {/* Filter Popup Overlay */}
      <FilterSwitch
        step={step}
        setStep={setStep}
        nodes={nodes}
        artists={artists}
        getLocation={getLocation}
      />
    </>
  );
};
